[data-role='more-options']:focus [role='img'] {
	visibility: visible !important;
}

.ui-sortable {
	cursor: pointer;
}

.ui-sortable > .ui-icon {
	visibility: hidden;
}

.ui-sortable.ui-sortable-active > .ui-icon {
	visibility: visible;
}

.ui-sortable.ui-sortable-sorted > .ui-icon {
	visibility: visible;
}
